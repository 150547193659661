import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { verifyCaptchaSignature } from "../../graphql/verifyCaptchaSignature"

import {
  getParamsFromURL,
  setLocalStorage,
  AUTOMATION_RECAPTCHA,
  ROUTES,
  publicAPI,
  captureException,
  SENTRY_ACTIONS,
} from "@lesmills/gatsby-theme-common"

const DisableCaptcha = () => {
  const [error, setError] = useState(undefined)

  useEffect(() => {
    const auth = getParamsFromURL("auth")
    const nonce = getParamsFromURL("nonce")
    publicAPI(
      verifyCaptchaSignature,
      async response => {
        const { data } = response.data
        if (data && data.verifyCaptchaSignature) {
          await setLocalStorage(AUTOMATION_RECAPTCHA, {
            auth: auth,
            nonce: nonce,
          })
          navigate(ROUTES().SALES_LANDING)
        } else {
          setError("Verify captcha signature failed")
          captureException({
            action: SENTRY_ACTIONS.VERIFY_CAPTCHA_SIGNATURE,
            requestVariables: {
              input: {
                auth,
                nonce,
              },
            },
            message: `verifyCaptchaSignature: ${data &&
              data.verifyCaptchaSignature}`,
          })
        }
      },
      err => {
        captureException({
          action: SENTRY_ACTIONS.VERIFY_CAPTCHA_SIGNATURE,
          requestVariables: {
            input: {
              auth,
              nonce,
            },
          },
          ...err,
        })
        setError(err)
      },
      {
        input: {
          auth,
          nonce,
        },
      }
    )
  }, [])

  return (
    <>{error && <p className="m-auto">Verify captcha signature failed</p>}</>
  )
}

export default DisableCaptcha
